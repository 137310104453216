<template>
  <ion-menu v-if="!isWebApp" contentId="main-content" menu-id="burger" slide="start" v-bind:disabled="disabled">
    <ion-header>
      <ion-toolbar color="rockease">
        <ion-buttons slot="start">
          <ion-button v-on:click="onCloseHamburgerClicked">
            <ion-icon slot="icon-only"
                      v-bind:icon="closeOutline"/>
          </ion-button>
        </ion-buttons>
        <ion-thumbnail slot="start" class="logo">
          <img alt="logo" class="toolbar-logo" src="@/assets/icon/logo-white.png">
        </ion-thumbnail>
        <ion-title>Rockease</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="menu ion-no-padding" mode="md">
        <template v-if="isLoggedIn">
          <driver-menu v-if="selectedRole === ROLE_DRIVER"/>
          <site-manager-menu v-if="selectedRole === ROLE_SITE_MANAGER"/>
        </template>

        <template v-if="items.length > 0">
          <main-menu-divider label="Actions"/>
          <template v-for="item in items" v-bind:key="item.label">
            <main-menu-item v-bind:icon="item.icon"
                            v-bind:label="item.label"
                            v-on:item-click="item.click"/>
          </template>
        </template>

        <template v-if="showDocuments">
          <main-menu-divider v-bind:label="t('documents.label')"/>
          <open-pdf-menu-item v-bind:href="userGuideLink"
                              v-bind:icon="bookOutline"
                              v-bind:label="t('documents.userGuide')"/>
          <open-pdf-menu-item v-bind:href="transportReceiptLink"
                              v-bind:icon="receiptOutline"
                              v-bind:label="t('documents.transportReceipt')"/>
        </template>

        <main-menu-divider v-bind:label="t('others.label')"/>
        <main-menu-item v-if="isLoggedIn"
                        v-bind:icon="cogOutline"
                        v-bind:label="t('others.parameters')"
                        v-on:click="onParametersClicked"/>
        <main-menu-item href="mailto:support@rockease.fr"
                        v-bind:icon="mailOutline"
                        v-bind:label="t('others.contactSupport')"/>
        <main-menu-item v-if="isLoggedIn"
                        v-bind:icon="logOutOutline"
                        v-bind:label="t('others.logout')"
                        v-on:click="onLogOutClicked"/>
      </ion-list>
      <div v-if="currentAppVersion"
           slot="fixed"
           class="app-version">
        v.{{ currentAppVersion }}
      </div>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {add, bookOutline, closeOutline, cogOutline, logOutOutline, mailOutline, receiptOutline} from 'ionicons/icons'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonMenu,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  menuController
} from '@ionic/vue'
import {useI18n} from 'vue-i18n'
import {mapActions, mapGetters} from 'vuex'
import MainMenuItem from '@/components/common/menu/MainMenuItem.vue'
import DriverMenu from '@/components/driver/DriverMenu.vue'
import {ROLE_DRIVER, ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'
import MainMenuDivider from '@/components/common/menu/MainMenuDivider.vue'
import SiteManagerMenu from '@/components/sitemanager/SiteManagerMenu.vue'
import {isWebApp} from '@/utils/OSUtils'
import OpenPdfMenuItem from '@/components/common/menu/OpenPdfMenuItem.vue'

// eslint-disable-next-line no-redeclare
export interface MainMenuItem {
  label: string
  icon?: string
  click?: () => Promise<void> | void
}

export default defineComponent({
  name: 'main-menu',
  props: {
    items: Object as PropType<Array<MainMenuItem>>,
    disabled: Boolean
  },
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })

    return {
      t,
      isWebApp,
      ROLE_DRIVER, ROLE_SITE_MANAGER,
      add, cogOutline, logOutOutline, mailOutline, closeOutline, bookOutline, receiptOutline
    }
  },
  methods: {
    ...mapActions(['logout']),
    async onCloseHamburgerClicked() {
      await menuController.close('burger')
    },
    async onParametersClicked() {
      await this.$router.replace({
        name: 'Parameters'
      })
    },
    async onLogOutClicked() {
      await this.logout()
      await this.$router.replace({
        name: 'Login'
      })
    }
  },
  computed: {
    ...mapGetters(['selectedRole', 'currentUser']),
    ...mapGetters('appVersion', ['currentAppVersion']),
    isLoggedIn(): boolean {
      return this.currentUser !== undefined
    },
    showDocuments(): boolean {
      const role = this.currentUser?.role
      return role === ROLE_DRIVER || role === ROLE_SITE_MANAGER
    },
    userGuideLink(): string | undefined {
      switch (this.currentUser?.role) {
      case ROLE_DRIVER:
        return 'https://storage.googleapis.com/rockease-public/Manual-Driver.pdf'
      case ROLE_SITE_MANAGER:
        return 'https://storage.googleapis.com/rockease-public/Manual-Site-manager.pdf'
      default:
        return undefined
      }
    },
    transportReceiptLink(): string | undefined {
      switch (this.currentUser?.role) {
      case ROLE_DRIVER:
      case ROLE_SITE_MANAGER:
        return 'https://storage.googleapis.com/rockease-public/Receipt-Transport.pdf'
      default:
        return undefined
      }
    }
  },
  components: {
    MainMenuItem, MainMenuDivider, OpenPdfMenuItem,
    DriverMenu, SiteManagerMenu,
    IonMenu,
    IonHeader, IonToolbar, IonThumbnail, IonTitle, IonButtons, IonButton, IonIcon,
    IonContent, IonList
  }
})
</script>

<style scoped>

ion-menu {
  --ion-background-color: var(--color-background-page-with-cards);
}

ion-thumbnail {
  --size: 35px;
  margin-left: 10px;
}

.menu {
  padding-bottom: 100px;
}

.app-version {
  width: 100%;
  bottom: 0;
  color: var(--color-muted);
  font-size: var(--font-size-xs);
  padding-right: var(--padding-xxxs);
  padding-bottom: var(--padding-md);
  text-align: end;
}

</style>

<i18n locale="en">
{
  "documents": {
    "label": "Documents",
    "userGuide": "User guide",
    "transportReceipt": "Transport receipt"
  },
  "others": {
    "label": "Others",
    "parameters": "Parameters",
    "contactSupport": "Contact support",
    "logout": "Log-out"
  }
}
</i18n>

<i18n locale="fr">
{
  "documents": {
    "label": "Documents",
    "userGuide": "Manuel Rockease",
    "transportReceipt": "Récépissé transport"
  },
  "others": {
    "label": "Autres",
    "parameters": "Paramètres",
    "contactSupport": "Contacter le support",
    "logout": "Se déconnecter"
  }
}
</i18n>
