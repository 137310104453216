import {DeliveryRequestFlow, getOrdersBySite, getSites} from '@/service/flow/sitemanager/DeliveryRequestFlow'
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex'
import {AppState} from '@/stores'
import {I18n} from 'vue-i18n'
import {SiteManagerDeliveryRequestBean, SiteManagerOrderApi, DisputeDto} from '@/service/api/sitemanager/SiteManagerOrderApi'
import {SiteBean} from '@/service/model/SiteBean'
import {SiteManagerOrderBean} from '@/service/model/sitemanager/SiteManagerOrderBean'
import {MaterialBean} from '@/service/model/MaterialBean'
import moment, {Moment} from 'moment'
import {makeException} from '@/exception/Exception'
import {translateMessage} from '@/i18n'
import {RouteLocationRaw} from 'vue-router'
import {Picture} from '@/service/model/PictureBean'

export const deliveryRequestOrderCodePrefix = 'DRO-'
export const deliveryRequestPageCodePrefix = 'DRP-'

export interface SiteManagerDeliveryRequestModuleState {
  flow?: DeliveryRequestFlow
}

export interface Location {
  latitude: number
  longitude: number
}
export class SiteManagerDeliveryRequestModule implements Module<SiteManagerDeliveryRequestModuleState, AppState> {
  namespaced = true
  state: SiteManagerDeliveryRequestModuleState = {}
  actions: ActionTree<SiteManagerDeliveryRequestModuleState, AppState> = {
    createFlow: this.createFlowAction.bind(this),
    fetchOrders: this.fetchOrdersAction.bind(this),
    fetchOrder: this.fetchOrderAction.bind(this),
    setSite: this.setSiteAction.bind(this),
    setOrder: this.setOrderAction.bind(this),
    setMaterial: this.setMaterialAction.bind(this),
    setBackloadMaterial: this.setBackloadMaterialAction.bind(this),
    setTrucksType: this.setTrucksTypeAction.bind(this),
    setQuantity: this.setQuantityAction.bind(this),
    setDay: this.setDayAction.bind(this),
    setTimePreference: this.setTimePreferenceAction.bind(this),
    setResponseTime: this.setResponseTimeAction.bind(this),
    setAddress1: this.setAddress1Action.bind(this),
    setAddress2: this.setAddress2Action.bind(this),
    setCity: this.setCityAction.bind(this),
    setPostalCode: this.setPostalCodeAction.bind(this),
    setLocation: this.setLocationAction.bind(this),
    setComment: this.setCommentAction.bind(this),
    requestDelivery: this.requestDeliveryAction.bind(this),
    fetchDeliveryRequests: this.fetchDeliveryRequestsAction.bind(this),
    getDeliveryRequest: this.getDeliveryRequestAction.bind(this),
    fetchDeliveryRequestByCode: this.fetchDeliveryRequestByCodeAction.bind(this),
    isDeliveryRequestCode: this.isDeliveryRequestCodeAction.bind(this),
    disputeDeliveryRequest: this.disputeDeliveryRequestAction.bind(this),
  }
  mutations: MutationTree<SiteManagerDeliveryRequestModuleState> = {
    setFlow: (state: SiteManagerDeliveryRequestModuleState, flow: DeliveryRequestFlow) => state.flow = flow,
    setPreselectedOrder: (state: SiteManagerDeliveryRequestModuleState, order?: SiteManagerOrderBean) => state.flow?.setPreselectedOrder(order),
    setOrders: (state: SiteManagerDeliveryRequestModuleState, openOrders: Array<SiteManagerOrderBean>) => state.flow?.updateItem(openOrders),
    setSite: (state: SiteManagerDeliveryRequestModuleState, site: SiteBean) => state.flow?.setSite(site),
    setOrder: (state: SiteManagerDeliveryRequestModuleState, order: SiteManagerOrderBean) => state.flow?.setOrder(order),
    setMaterial: (state: SiteManagerDeliveryRequestModuleState, material?: MaterialBean) => state.flow?.setMaterial(material),
    setBackloadMaterial: (state: SiteManagerDeliveryRequestModuleState, backloadMaterial?: MaterialBean) => state.flow?.setBackloadMaterial(backloadMaterial),
    setTrucksType: (state: SiteManagerDeliveryRequestModuleState, trucksType?: string) => state.flow?.setTrucksType(trucksType),
    setQuantity: (state: SiteManagerDeliveryRequestModuleState, quantity?: number) => state.flow?.setQuantity(quantity),
    setDay: (state: SiteManagerDeliveryRequestModuleState, day?: string) => state.flow?.setDay(day),
    setTimePreference: (state: SiteManagerDeliveryRequestModuleState, timePreference?: string) => state.flow?.setTimePreference(timePreference),
    setResponseTime: (state: SiteManagerDeliveryRequestModuleState, responseTime?: string) => state.flow?.setResponseTime(responseTime),
    setAddress1: (state: SiteManagerDeliveryRequestModuleState, address1?: string) => state.flow?.setAaddress1(address1),
    setAddress2: (state: SiteManagerDeliveryRequestModuleState, address2?: string) => state.flow?.setAaddress2(address2),
    setCity: (state: SiteManagerDeliveryRequestModuleState, city?: string) => state.flow?.setCity(city),
    setPostalCode: (state: SiteManagerDeliveryRequestModuleState, postalCode?: string) => state.flow?.setPostalCode(postalCode),
    setLocation: (state: SiteManagerDeliveryRequestModuleState, location?: Location) => state.flow?.setLocation(location),
    setComment: (state: SiteManagerDeliveryRequestModuleState, comment?: string) => state.flow?.setComment(comment)
  }
  getters: GetterTree<SiteManagerDeliveryRequestModuleState, AppState> = {
    flow: (state) => state.flow,
    orders: (state) => state.flow?.getItem()
  }
  
  constructor(
    private readonly i18n: I18n,
    private readonly orderApi: SiteManagerOrderApi
  ) {
  }
  
  async createFlowAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    params?: {initialRoute?: RouteLocationRaw; order?: SiteManagerOrderBean}
  ): Promise<DeliveryRequestFlow> {
    const flow = new DeliveryRequestFlow(params?.initialRoute)
    context.commit('setFlow', flow)
    
    if (params?.order !== undefined) {
      context.commit('setPreselectedOrder', params?.order)
    }
    
    return flow
  }
  
  async fetchOrdersAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>
  ): Promise<boolean> {
    let skipCompletedSteps = false
    const orders = await this.orderApi.fetchDeliveredOrders()
    context.commit('setOrders', orders)
    
    const sites = getSites(orders)
    if (sites.length === 1) {
      skipCompletedSteps = true
      await context.dispatch('setSite', sites[0])
    }
    return skipCompletedSteps
  }

  async setSiteAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    site?: SiteBean
  ): Promise<boolean> {
    let skipCompleted = false
    context.commit('setSite', site)
    
    const flow = context.state.flow
    if (flow !== undefined && site !== undefined) {
      const orders = getOrdersBySite(flow.getItem(), site)
      if (orders.length === 1) {
        skipCompleted = true
        await context.dispatch('setOrder', orders[0])
      }
    }
    return skipCompleted
  }
  
  setOrderAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    order?: SiteManagerOrderBean
  ) {
    context.commit('setOrder', order)
  }
  
  setMaterialAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    material?: MaterialBean
  ) {
    context.commit('setMaterial', material)
  }

  setTrucksTypeAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    trucksType?: string
  ) {
    context.commit('setTrucksType', trucksType)
  }
  
  setQuantityAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    quantity?: number
  ) {
    context.commit('setQuantity', quantity)
  }

  setBackloadMaterialAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    backloadMaterial?: MaterialBean
  ) {
    context.commit('setBackloadMaterial', backloadMaterial)
  }
  
  setDayAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    day?: Moment
  ) {
    if (day === undefined) {
      context.commit('setDay', undefined)
    } else {
      const formattedDay = day.format(moment.HTML5_FMT.DATE)
      context.commit('setDay', formattedDay)
    }
  }
  
  setTimePreferenceAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    timePreference?: string
  ) {
    context.commit('setTimePreference', timePreference)
  }
  
  setResponseTimeAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    responseTime?: string
  ) {
    context.commit('setResponseTime', responseTime)
  }


  setAddress1Action(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    address1?: string
  ) {
    context.commit('setAddress1', address1)
  }

  setAddress2Action(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    address2?: string
  ) {
    context.commit('setAddress2', address2)
  }

  setCityAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    city?: string
  ) {
    context.commit('setCity', city)
  }

  setPostalCodeAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    postalCode?: string
  ) {
    context.commit('setPostalCode', postalCode)
  }

  setLocationAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    location?: Location
  ) {
    context.commit('setLocation', location)
  }

  setCommentAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    comment?: string
  ) {
    context.commit('setComment', comment)
  }
  
  async requestDeliveryAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>
  ) {
    const bean = this.getOrderIdAndBean(context.state)
    if (bean === undefined) {
      throw makeException(this.i18n, 'deliveryRequest.unknown')
    }
    
    await this.orderApi.requestDelivery(bean.bean)
    await context.dispatch(
      'message/setMessage',
      {message: translateMessage(this.i18n, 'deliveryRequest.success')},
      {root: true}
    )
    
    context.commit('setFlow', undefined)
  }

  async fetchDeliveryRequestsAction(): Promise<Array<SiteManagerDeliveryRequestBean>> {
    const deliveryRequest = await this.orderApi.fetchDeliveryRequests()
    if (!deliveryRequest) {
      throw makeException(this.i18n, 'deliveryRequest.unknown')
    }
    return deliveryRequest
  }

  async getDeliveryRequestAction(    
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    iddr: string
  ): Promise<SiteManagerDeliveryRequestBean> {
    const deliveryRequest =  await this.orderApi.getDeliveryRequest(iddr)

    if (!deliveryRequest) {
      throw makeException(this.i18n, 'deliveryRequest.unknown')
    }
    return deliveryRequest
  }
  
  async fetchOrderAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    id: string
  ): Promise<SiteManagerOrderBean> {
    const order = await this.orderApi.fetchOrder(id)
    return order
  }

  formatTruckType(texte) {
    texte = texte.replace(/ /g, '_')
    if (texte[0].match(/\d/)) {
      texte = '_' + texte
    }
    return texte
  }
  
  
  async fetchDeliveryRequestByCodeAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    siteManagerCode: string
  ): Promise<SiteManagerDeliveryRequestBean> {
    const deliveryRequest = await this.orderApi.fetchDeliveryRequestByCode(siteManagerCode)
    if (!deliveryRequest) {
      throw makeException(this.i18n, 'auth.noDeliveryRequestAssociatedToCode')
    }
    return deliveryRequest
  }
  
  
  isDeliveryRequestCodeAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    siteManagerCode: string
  ): boolean {
    return siteManagerCode.startsWith(deliveryRequestOrderCodePrefix) || siteManagerCode.startsWith(deliveryRequestPageCodePrefix)
  }
  
  async disputeDeliveryRequestAction(
    context: ActionContext<SiteManagerDeliveryRequestModuleState, AppState>,
    params: { id: string; dto: DisputeDto; picture: Picture }
  ): Promise<void> {
    try {
      await this.orderApi.disputeDeliveryRequest({
        id: params.id,
        disputeDto: params.dto,
        picture: params.picture
      })
      await context.dispatch(
        'message/setMessage',
        {message: translateMessage(this.i18n, 'deliveryRequest.dispute')},
        {root: true}
      )
    } catch (error) {
      console.error('Error requesting dispute:', error)
      throw error
    }
  }

  getOrderIdAndBean(state: SiteManagerDeliveryRequestModuleState): { orderId: string; bean: SiteManagerDeliveryRequestBean } | undefined {
    const flowState = state.flow?.getState()
    if (
      flowState?.order?.id === undefined
      || flowState?.site?.id === undefined
      || flowState?.material?.id === undefined
      || flowState?.day === undefined
      || flowState?.timePreference === undefined
    ) {
      return undefined
    }

    const [startTimeStr, endTimeStr] = flowState.timePreference.split(' - ')

    const date1 = flowState.timePreference !== 'indifferent' ? moment(startTimeStr, 'HH[h]').format('HH:mm') : ''
    const date2 = flowState.timePreference !== 'indifferent' ? moment(endTimeStr, 'HH[h]').format('HH:mm') : ''

    const trucks = flowState.trucksType !== 'indifferent' ? flowState.trucksType : ''

    const delay: number | null = flowState.responseTime ? parseInt(flowState.responseTime, 10) : null
    
    return {
      orderId: flowState.order.id,
      bean: {
        orderId: flowState.order?.id,
        siteManagerId: flowState.order?.site.id,
        deliveryDate: moment(flowState.day, moment.HTML5_FMT.DATE).format(moment.HTML5_FMT.DATETIME_LOCAL),
        timeSlotDeliveryStartTime:  date1,
        timeSlotDeliveryEndTime: date2,
        responseDelay: delay,
        quantity:   flowState.quantity,
        truckType: this.formatTruckType(trucks),
        comment:   flowState.comment,
        status: 'PENDING',
        backloadDeliveryOnly: flowState.material.category == 'WASTE' ? true : false,
        materialDeliveryOnly: flowState.material.category == 'MATERIAL' && flowState.backloadMaterial == undefined ? true : false,
        materialDeliveryAndBackloadReturn: flowState.material.category == 'MATERIAL' && flowState.backloadMaterial !== undefined ? true : false,
        address1:   flowState.address1,
        address2:   flowState.address2,
        postalCode:   flowState.postalCode,
        city:   flowState.city,
        location: flowState.location
      }
    }
  }
}
