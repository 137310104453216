<template>
  <template v-if="to">
    <router-link v-slot="{ navigate, isExactActive }" custom replace v-bind:to="to">
      <ion-item class="ion-activatable ripple-parent"
                lines="full"
                v-bind:class="{'active': isExactActive, 'inactive': !isExactActive}"
                v-on:click="onItemClicked(navigate)">
        <ion-icon v-if="icon" size="small" slot="start" v-bind:icon="icon"/>
        <ion-icon v-else-if="isExactActive" size="small" slot="start" v-bind:icon="checkmark"/>
        <ion-label>{{ label }}</ion-label>
        <ion-ripple-effect/>
      </ion-item>
    </router-link>
  </template>
  <template v-else>
    <ion-item class="ion-activatable ripple-parent" lines="full" v-on:click="onItemClicked(emitsItemClick)">
      <ion-icon size="small" v-if="icon" slot="start" v-bind:icon="icon"/>
      <ion-label>{{ label }}</ion-label>
      <ion-ripple-effect/>
    </ion-item>
  </template>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import {IonIcon, IonItem, IonLabel, IonRippleEffect, menuController} from '@ionic/vue'
import {RouteLocationRaw, RouterLink} from 'vue-router'
import {checkmark} from 'ionicons/icons'

export default defineComponent({
  name: 'main-menu-item',
  emits: ['item-click'],
  setup() {
    return {checkmark}
  },
  props: {
    icon: String,
    label: String,
    to: Object as PropType<RouteLocationRaw>
  },
  methods: {
    async onItemClicked(fn?: () => any) {
      await menuController.close('burger')
      if (fn) {
        fn()
      }
    },
    emitsItemClick() {
      this.$emit('item-click')
    }
  },
  components: {
    RouterLink,
    IonItem, IonIcon, IonLabel, IonRippleEffect
  }
})
</script>

<style scoped>

ion-item {
  --ion-background-color: var(--color-background);
}

ion-icon {
  margin-right: 12px !important;
}

.active {
  border-right: var(--spacing-xxxs) solid var(--ion-color-rockease);
}

.active ion-icon {
  color: var(--ion-text-color);
}

.active ion-label {
  font-weight: 600;
}

.inactive ion-label {
  margin-left: 30px; /* 18px (icon width) + 12px (icon padding) */
}

</style>
