<template>
  <main-menu-divider v-bind:label="t('quotation.title')"/>
  <main-menu-item v-bind:label="t('quotation.list')"
                  v-bind:to="{name: 'SiteManagerQuotationRequests'}"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('quotation.add')"
                  v-on:item-click="onQuotationRequestClicked"/>

  <main-menu-divider v-bind:label="t('order.title')"/>
  <main-menu-item v-bind:label="t('order.list')"
                  v-bind:to="{name: 'SiteManagerOrders'}"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('order.addExpressOrder')"
                  v-on:item-click="onCreateExpressOrderClicked"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('order.deliveryRequest')"
                  v-on:item-click="onDeliveryRequestClicked"/>

  <main-menu-divider v-bind:label="t('deliveryRequest.title')"/>
  <main-menu-item v-bind:label="t('deliveryRequest.accepted')"
                  v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'accepted'} }"/>
  <main-menu-item v-bind:label="t('deliveryRequest.inProgress')"
                  v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'pending'} }"/>
  <main-menu-item v-bind:label="t('deliveryRequest.refused')"
                  v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'rejected'} }"/>
  <main-menu-item v-bind:label="t('deliveryRequest.expired')"
                  v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'expired'} }"/>
  <main-menu-item v-bind:label="t('deliveryRequest.delivered')"
                  v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'delivered'} }"/>
  <main-menu-item v-bind:label="t('deliveryRequest.disputes')"
                  v-bind:to="{ name: 'SiteManagerDeliveryRequestList', params: { status: 'disputed'} }"/>


  <main-menu-divider v-bind:label="t('deliveryNote.title')"/>
  <main-menu-item v-bind:label="t('deliveryNote.upcoming')"
                  v-bind:to="{name: SiteManagerDeliveryNotesRoute.UPCOMING}"/>
  <main-menu-item v-bind:label="t('deliveryNote.delivered')"
                  v-bind:to="{name: SiteManagerDeliveryNotesRoute.DELIVERED}"/>
  <main-menu-item v-bind:icon="add"
                  v-bind:label="t('deliveryNote.addPickupDeliveryNote')"
                  v-on:item-click="onCreatePickupDeliveryNoteButtonClicked"/>

  <main-menu-divider v-bind:label="t('config.title')"/>
  <main-menu-item v-bind:label="t('config.drivers')"
                  v-bind:to="{name: 'SiteManagerDrivers'}"/>
  <main-menu-item v-bind:label="t('config.truckRegistrations')"
                  v-bind:to="{name: 'SiteManagerTrucks'}"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {useI18n} from 'vue-i18n'
import MainMenuItem from '@/components/common/menu/MainMenuItem.vue'
import MainMenuDivider from '@/components/common/menu/MainMenuDivider.vue'
import {SiteManagerDeliveryNotesRoute} from '@/router/sitemanager'
import {mapActions} from 'vuex'
import {add} from 'ionicons/icons'

export default defineComponent({
  name: 'site-manager-menu',
  setup() {
    const {t} = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return {
      t,
      add,
      SiteManagerDeliveryNotesRoute
    }
  },
  methods: {
    ...mapActions('siteManager/expressOrder', ['createExpressOrderFlow']),
    ...mapActions('siteManager/deliveryRequest', {
      createDeliveryRequestFlow: 'createFlow'
    }),
    ...mapActions('siteManager/quotationRequest/create', ['createQuotationRequestFlow']),
    ...mapActions('siteManager/pickup', ['createPickupFlow']),
    async onCreateExpressOrderClicked() {
      const flow = await this.createExpressOrderFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    },
    async onDeliveryRequestClicked() {
      const flow = await this.createDeliveryRequestFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    },
    async onQuotationRequestClicked() {
      const flow = await this.createQuotationRequestFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    },
    async onCreatePickupDeliveryNoteButtonClicked() {
      const flow = await this.createPickupFlow({
        initialRoute: this.$route
      })
      const nextRoute = flow.getNextRoute()
      await this.$router.replace(nextRoute)
    }
  },
  components: {
    MainMenuDivider, MainMenuItem
  }
})
</script>

<i18n locale="en">
{
  "quotation": {
    "title": "Quotations",
    "list": "Quotation requests",
    "add": "New request"
  },
  "order": {
    "title": "Orders",
    "list": "Orders",
    "addExpressOrder": "New express order",
    "deliveryRequest": "New delivery request"
  },
  "deliveryRequest": {
    "title": "Delivery calls",
    "accepted": "Accepted",
    "inProgress": "In Progress",
    "refused": "Refused",
    "expired": "Expired",
    "delivered": "Delivered",
    "disputes": "Disputes"
  },
  "deliveryNote": {
    "title": "Delivery notes",
    "upcoming": "Upcoming",
    "delivered": "Delivered",
    "addPickupDeliveryNote": "New pickup delivery"
  },
  "config": {
    "title": "Configuration",
    "drivers": "Drivers",
    "truckRegistrations": "Trucks"
  }
}
</i18n>

<i18n locale="fr">
{
  "quotation": {
    "title": "Devis",
    "list": "Demandes de devis",
    "add": "Nouvelle demande"
  },
  "order": {
    "title": "Commandes",
    "list": "Commandes",
    "addExpressOrder": "Nouvelle commande express",
    "deliveryRequest": "Appel de livraison"
  },
  "deliveryRequest": {
    "title": "Appels de livraison",
    "accepted": "Acceptés",
    "inProgress": "En cours",
    "refused": "Refusés",
    "expired": "Expirés",
    "delivered": "Livrés",
    "disputes": "Litiges"
  },
  "deliveryNote": {
    "title": "Bons de livraison",
    "upcoming": "En cours",
    "delivered": "Terminés",
    "addPickupDeliveryNote": "Nouveau bon départ"
  },
  "config": {
    "title": "Configuration",
    "drivers": "Chauffeurs",
    "truckRegistrations": "Camions"
  }
}
</i18n>
