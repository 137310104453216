import {NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import {Store} from 'vuex'
import {AppState} from '@/stores'

export const SiteManagerDeliveryNoteDetailsRoute = 'SiteManagerDeliveryNoteDetails'

export const SiteManagerDeliveryNotesRoute = {
  UPCOMING: 'SiteManagerUpcomingDeliveryNotes',
  PENDING: 'SiteManagerPendingDeliveryNotes',
  DELIVERED: 'SiteManagerDeliveredDeliveryNotes'
}

export const SiteManagerDeliveryNoteConfirmRoute = {
  CONFIRM: 'SiteManagerDeliveryNoteConfirm',
  WEIGHING_SLIP: 'SiteManagerDeliveryNoteTakeWeighingSlip',
  WEIGHT: 'SiteManagerDeliveryNoteConfirmWeight',
  DAY: 'SiteManagerDeliveryNoteConfirmDay',
  TRUCK_REGISTRATION: 'SiteManagerDeliveryNoteConfirmTruckRegistration',
  CONFIRM_WITH_COMMENT: 'SiteManagerDeliveryNoteConfirmWithComment',
  COMMENTS: 'SiteManagerDeliveryNoteSetComment'
}

export const SiteManagerExpressOrderRoute = {
  SITE: 'SiteManagerExpressOrderSite',
  CUSTOMER: 'SiteManagerExpressOrderCustomer',
  TEMPORARY_SITE: 'SiteManagerExpressOrderTemporarySite',
  MATERIAL: 'SiteManagerExpressOrderMaterial',
  QUANTITY: 'SiteManagerExpressOrderQuantity',
  RESPONSES: 'SiteManagerExpressOrderResponses',
  CONFIRM: 'SiteManagerExpressOrderConfirm'
}


export const SiteManagerDeliveryRequestRoute = {
  SELECT_SITE: 'SiteManagerDeliveryRequestSelectSite',
  SELECT_ORDER: 'SiteManagerDeliveryRequestSelectOrder',
  MATERIAL_CHOICE: 'SiteManagerDeliveryRequestMaterialChoice',
  TRUCKS_CHOICE: 'SiteManagerDeliveryRequestTrucksChoice',
  DAY: 'SiteManagerDeliveryRequestDay',
  CONFIRM: 'SiteManagerDeliveryRequestConfirm',
}

export const SiteManagerDeliveryRequestListRoute = {
  STATUS : 'SiteManagerDeliveryRequestList',
  DETAILS : 'SiteManagerDeliveryRequestDetails',
  DISPUTE : 'SiteManagerDeliveryRequestDispute'
}
export const SiteManagerCreatePickupRoute = {
  SELECT_ORDER: 'SiteManagerSelectPickupOrder',
  TRIP_COUNT: 'SiteManagerCreatePickup',
  CONFIRM: 'SiteManagerConfirmCreatePickup'
}

export const SiteManagerAddDriverRoute = {
  SELECT_CUSTOMER: 'SiteManagerAddDriverSelectCustomer',
  CONFIRM: 'SiteManagerAddDriverConfirm',
}

export const SiteManagerAddTruckRoute = {
  SELECT_CUSTOMER: 'SiteManagerAddTruckSelectCustomer',
  CONFIRM: 'SiteManagerAddTruckConfirm',
}

export const SiteManagerQuotationRequestRoute = {
  SELECT_SITE: 'SiteManagerQuotationRequestSelectSite',
  SELECT_CUSTOMER: 'SiteManagerQuotationRequestSelectCustomer',
  CREATE_SITE: 'SiteManagerQuotationRequestCreateSite',
  DELIVERY_TYPE: 'SiteManagerQuotationRequestDeliveryType',
  SELECT_MATERIAL: 'SiteManagerQuotationRequestSelectMaterial',
  SELECT_BACKLOAD_MATERIAL: 'SiteManagerQuotationRequestSelectBackloadMaterial',
  QUANTITY: 'SiteManagerQuotationRequestQuantity',
  SELECT_TRUCK: 'SiteManagerQuotationRequestSelectTruck',
  DAY: 'SiteManagerQuotationRequestDay',
  CONFIRM: 'SiteManagerQuotationRequestConfirm',
  TECH_SPECH: 'SiteManagerQuotationResponse'
}

export function makeSiteManagerRoutes(
  store: Store<AppState>
): Array<RouteRecordRaw> {
  
  function checkHasLoadingDeliveryNoteBeforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const deliveryNoteId = to.params.id
    const deliveryNote = store.getters['unload/deliveryNote']
    if (!deliveryNote || deliveryNote.id !== deliveryNoteId) {
      next({
        name: SiteManagerDeliveryNoteDetailsRoute,
        params: {id: to.params.id}
      })
    } else next()
  }
  
  function checkHasExpressOrderFlowBeforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const flow = store.getters['siteManager/expressOrder/flow']
    if (!flow) {
      next({
        name: SiteManagerExpressOrderRoute.SITE
      })
    } else next()
  }
  
  return [
    {
      path: '/site-manager',
      name: 'SiteManagerHome',
      redirect: {name: SiteManagerDeliveryNotesRoute.UPCOMING}
    },
    {
      path: '/site-manager/upcoming',
      name: SiteManagerDeliveryNotesRoute.UPCOMING,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/SiteManagerUpcomingDeliveryNoteListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/pending',
      name: SiteManagerDeliveryNotesRoute.PENDING,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/SiteManagerPendingDeliveryNoteListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivered',
      name: SiteManagerDeliveryNotesRoute.DELIVERED,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/SiteManagerDeliveredDeliveryNoteListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id',
      name: SiteManagerDeliveryNoteDetailsRoute,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/SiteManagerDeliveryNoteDetailsPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/edit/comment',
      name: 'SiteManagerDeliveryNoteEditComment',
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/SiteManagerDeliveryNoteSetOrUpdateCommentPage.vue'),
      meta: {
        method: 'edit',
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/edit/weighing-slip',
      name: 'SiteManagerDeliveryNoteAddWeighingSlipPicture',
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/edit/SiteManagerDeliveryNoteAddWeighingSlipPicturePage.vue'),
      meta: {
        method: 'edit',
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/pictures/weighing-slip',
      name: 'SiteManagerDeliveryNoteWeighingSlipPicture',
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/picture/SiteManagerDeliveryNoteWeighingSlipPicturePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/pictures/load',
      name: 'SiteManagerDeliveryNoteLoadPicture',
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/picture/SiteManagerDeliveryNoteLoadPicturePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/pictures/unload',
      name: 'SiteManagerDeliveryNoteUnloadPicture',
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/picture/SiteManagerDeliveryNoteUnloadPicturePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/orders/pick-up/select-order',
      name: SiteManagerCreatePickupRoute.SELECT_ORDER,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/create/SiteManagerSelectPickupOrderPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/orders/pick-up/create',
      name: SiteManagerCreatePickupRoute.TRIP_COUNT,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/create/SiteManagerCreatePickupPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/orders/pick-up/confirm',
      name: SiteManagerCreatePickupRoute.CONFIRM,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/create/SiteManagerConfirmCreatePickupPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/confirm',
      name: SiteManagerDeliveryNoteConfirmRoute.CONFIRM,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/confirm/SiteManagerDeliveryNoteConfirmPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/take-weighing-slip',
      name: SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/confirm/SiteManagerDeliveryNoteTakeWeighingSlipPicturePage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/confirm-weight',
      name: SiteManagerDeliveryNoteConfirmRoute.WEIGHT,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/confirm/SiteManagerDeliveryNoteConfirmWeightPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/confirm-day',
      name: SiteManagerDeliveryNoteConfirmRoute.DAY,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/confirm/SiteManagerDeliveryNoteConfirmDayPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/confirm-truck-registration',
      name: SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/confirm/SiteManagerDeliveryNoteConfirmTruckRegistrationPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/confirm-with-comment',
      name: SiteManagerDeliveryNoteConfirmRoute.CONFIRM_WITH_COMMENT,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/confirm/SiteManagerDeliveryNoteConfirmWithCommentPage.vue'),
      beforeEnter: checkHasLoadingDeliveryNoteBeforeEnter,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-notes/:id/confirm-comment',
      name: SiteManagerDeliveryNoteConfirmRoute.COMMENTS,
      component: () => import(/* webpackChunkName: "sitemanager" */ '@/views/sitemanager/SiteManagerDeliveryNoteSetOrUpdateCommentPage.vue'),
      meta: {
        method: 'set',
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/sites',
      name: SiteManagerExpressOrderRoute.SITE,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderSitePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/customers',
      name: SiteManagerExpressOrderRoute.CUSTOMER,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderCustomerPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/temporary-site',
      name: SiteManagerExpressOrderRoute.TEMPORARY_SITE,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderTemporarySitePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/material',
      name: SiteManagerExpressOrderRoute.MATERIAL,
      beforeEnter: checkHasExpressOrderFlowBeforeEnter,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderMaterialPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/quantity',
      name: SiteManagerExpressOrderRoute.QUANTITY,
      beforeEnter: checkHasExpressOrderFlowBeforeEnter,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderQuantityPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/responses',
      name: SiteManagerExpressOrderRoute.RESPONSES,
      beforeEnter: checkHasExpressOrderFlowBeforeEnter,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderResponsesPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/express-order/confirm',
      name: SiteManagerExpressOrderRoute.CONFIRM,
      beforeEnter: checkHasExpressOrderFlowBeforeEnter,
      component: () => import(/* webpackChunkName: "sitemanager-expressorder" */ '@/views/sitemanager/expressorder/SiteManagerExpressOrderConfirmPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/site',
      name: SiteManagerDeliveryRequestRoute.SELECT_SITE,
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestSelectSitePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/order',
      name: SiteManagerDeliveryRequestRoute.SELECT_ORDER,
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestSelectOrderPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/material-choice',
      name: SiteManagerDeliveryRequestRoute.MATERIAL_CHOICE,
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestMaterialChoicePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/trucks-choice',
      name: SiteManagerDeliveryRequestRoute.TRUCKS_CHOICE,
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestTrucksChoicePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/day',
      name: SiteManagerDeliveryRequestRoute.DAY,
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestDayPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/confirm',
      name: SiteManagerDeliveryRequestRoute.CONFIRM,
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestConfirmPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/:status',
      name: 'SiteManagerDeliveryRequestList',
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/detail/:id',
      name: 'SiteManagerDeliveryRequestDetails',
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestDetailsPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/:id/dispute',
      name: 'SiteManagerDeliveryRequestDispute',
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestDisputePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/delivery-request/:id/dispute/picture',
      name: 'SiteManagerDeliveryRequestTakePicture',
      component: () => import(/* webpackChunkName: "sitemanager-deliveryrequest" */ '@/views/sitemanager/deliveryrequest/SiteManagerDeliveryRequestTakePicturePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/drivers',
      name: 'SiteManagerDrivers',
      component: () => import(/* webpackChunkName: "sitemanager-driver" */ '@/views/sitemanager/driver/SiteManagerDriverListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/drivers/add/customers',
      name: SiteManagerAddDriverRoute.SELECT_CUSTOMER,
      component: () => import(/* webpackChunkName: "sitemanager-driver" */ '@/views/sitemanager/driver/SiteManagerAddDriverSelectCustomerPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/drivers/add/confirm',
      name: SiteManagerAddDriverRoute.CONFIRM,
      component: () => import(/* webpackChunkName: "sitemanager-driver" */ '@/views/sitemanager/driver/SiteManagerAddDriverConfirmPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/trucks',
      name: 'SiteManagerTrucks',
      component: () => import(/* webpackChunkName: "sitemanager-truck" */ '@/views/sitemanager/truck/SiteManagerTruckListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/trucks/add/customers',
      name: SiteManagerAddTruckRoute.SELECT_CUSTOMER,
      component: () => import(/* webpackChunkName: "sitemanager-truck" */ '@/views/sitemanager/truck/SiteManagerAddTruckSelectCustomerPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/trucks/add/confirm',
      name: SiteManagerAddTruckRoute.CONFIRM,
      component: () => import(/* webpackChunkName: "sitemanager-truck" */ '@/views/sitemanager/truck/SiteManagerAddTruckConfirmPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests',
      name: 'SiteManagerQuotationRequests',
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/:id',
      name: 'SiteManagerQuotationRequest',
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/:requestId/response/:responseId',
      name: 'SiteManagerQuotationSelectResponse',
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerSelectQuotationResponsePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/:requestId/response/:responseId/tech-spec',
      name: 'SiteManagerQuotationResponseTechSpec',
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationResponseTechSpecPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/select-site',
      name: SiteManagerQuotationRequestRoute.SELECT_SITE,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestSelectSitePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/select-customer',
      name: SiteManagerQuotationRequestRoute.SELECT_CUSTOMER,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestSelectCustomerPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/create-site',
      name: SiteManagerQuotationRequestRoute.CREATE_SITE,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestCreateSitePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/delivery-type',
      name: SiteManagerQuotationRequestRoute.DELIVERY_TYPE,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestDeliveryTypePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/select-material',
      name: SiteManagerQuotationRequestRoute.SELECT_MATERIAL,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationResponseSelectMaterialPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/select-backload-material',
      name: SiteManagerQuotationRequestRoute.SELECT_BACKLOAD_MATERIAL,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationResponseSelectBackloadMaterialPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/quantity',
      name: SiteManagerQuotationRequestRoute.QUANTITY,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationResponseQuantityPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/select-truck',
      name: SiteManagerQuotationRequestRoute.SELECT_TRUCK,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestSelectTruckTypePage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/day',
      name: SiteManagerQuotationRequestRoute.DAY,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestDayPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/quotation-requests/create/confirm',
      name: SiteManagerQuotationRequestRoute.CONFIRM,
      component: () => import(/* webpackChunkName: "sitemanager-quotation-request" */ '@/views/sitemanager/quotationrequest/SiteManagerQuotationRequestConfirmPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/orders',
      name: 'SiteManagerOrders',
      component: () => import(/* webpackChunkName: "sitemanager-order" */ '@/views/sitemanager/order/SiteManagerOrderListPage.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/site-manager/orders/:id',
      name: 'SiteManagerOrder',
      component: () => import(/* webpackChunkName: "sitemanager-order" */ '@/views/sitemanager/order/SiteManagerOrderPage.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ]
}
