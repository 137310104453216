import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.message)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        color: "white",
        lines: "full"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.message), 1),
          _createVNode(_component_ion_button, {
            slot: "end",
            color: "rockease",
            target: "_blank",
            href: _ctx.storeLink
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('button.install')), 1)
            ]),
            _: 1
          }, 8, ["href"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}