import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {SiteManagerDeliveryNoteApi} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import {DeliveryNoteBean, DeliveryStatus, DeliveryType, getMostAccurateDay} from '@/service/model/DeliveryNoteBean'
import {LocationBean} from '@/service/model/LocationBean'
import {
  DeliveryNoteConfirmState,
  ExternalConfirmInfoBean,
  SetPictureBeanParams,
  SetPictureParams
} from '@/stores/modules/DeliveryNoteConfirmModule'
import {BroadcastPromise} from '@/utils/BroadcastPromise'
import {PictureBean} from '@/service/model/PictureBean'
import {LocalStore} from '@/service/store/LocalStore'
import {getItemOperation} from '@/service/operation/GetOperation'
import {I18n} from 'vue-i18n'
import {makeException} from '@/exception/Exception'
import moment, {Moment} from 'moment'
import {SiteManagerDeliveryNotePictureApi} from '@/service/api/sitemanager/SiteManagerDeliveryNotePictureApi'
import {SiteManagerDeliveryNoteConfirmRoute, SiteManagerDeliveryNoteDetailsRoute} from '@/router/sitemanager'
import {MaterialType} from '@/service/model/MaterialBean'
import _ from 'lodash'
import {IonicProxyService} from '@/service/IonicProxyService'
import {PushNotificationService} from '@/service/PushNotificationService'
import {PushNotificationEvent} from '@/service/model/PushNotificationEvent'
import {
  DeliveryNoteStatusChangedBean,
  deliveryNoteStatusChangedSchema
} from '@/service/model/DeliveryNoteStatusChangedBean'
import {translateMessage} from '@/i18n'
import {Subscription} from '@/service/subscription/Subscription'
import {parseDateLocal, todayLocal} from '@/utils/DateUtils'
import {SynchronizeResult} from '@/service/SynchronizationService'
import {DeliveryNoteSyncService} from '@/service/DeliveryNoteSyncService'
import {ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'

export type SiteManagerConfirmFlow =
  'LOAD_WASTE'
  | 'UNLOAD_MATERIAL'
  | 'UNLOAD_WASTE'
  | 'UNLOAD_WASTE_CUSTOMER'
  | 'UNLOAD_MATERIAL_CUSTOMER'
export type SiteManagerConfirmType = 'BY_VALIDATION' | 'BY_PICTURE'

const STORAGE_KEY = 'site-manager-confirm'
const STORE_KEY = 'site-manager-confirm'
const DELIVERY_NOTE_VALIDITY = 24 * 60 * 60 * 1000 // 24 h in ms

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SiteManagerDeliveryNoteConfirmState {
  needReloading: boolean
  deliveryNote: DeliveryNoteBean | null
  info: SiteManagerConfirmInfoBean | null
  externalInfo: SiteManagerExternalConfirmInfoBean | null
  
  weighingSlipPictureUpload?: BroadcastPromise<SynchronizeResult<void>>
  
  storeSubscription?: Subscription
  pushNotificationSubscription?: Subscription
}

/**
 * '| null' for optional parameters
 */
export interface SiteManagerConfirmInfoBean {
  deliveryNoteId: string
  flow: SiteManagerConfirmFlow
  type: SiteManagerConfirmType
  
  location?: LocationBean
  day?: string | null
  netWeight?: number | null
  truckRegistration?: string | null
  comment?: string
  
  weighingSlipPicture?: PictureBean | null
}

export interface SiteManagerExternalConfirmInfoBean {
  message: string
}

function computeSiteManagerConfirmFlowForCustomer(deliveryNote: DeliveryNoteBean): SiteManagerConfirmFlow | undefined {
  switch (deliveryNote.status) {
  case DeliveryStatus.PENDING:
    if (deliveryNote.material.type === MaterialType.NORMAL) {
      return 'UNLOAD_MATERIAL_CUSTOMER'
    } else {
      return 'UNLOAD_WASTE_CUSTOMER'
    }
  case DeliveryStatus.LOADING:
  case DeliveryStatus.IN_DELIVERY:
    if (deliveryNote.material.type === MaterialType.NORMAL) {
      return 'UNLOAD_MATERIAL'
    } else {
      return 'UNLOAD_WASTE'
    }
  case DeliveryStatus.DELIVERED:
  default:
    return undefined
  }
}

function computeSiteManagerConfirmFlowForHauler(deliveryNote: DeliveryNoteBean): SiteManagerConfirmFlow | undefined {
  switch (deliveryNote.status) {
  case DeliveryStatus.PENDING:
    if (deliveryNote.material.type === MaterialType.NORMAL) {
      return 'UNLOAD_MATERIAL'
    } else {
      return 'LOAD_WASTE'
    }
  case DeliveryStatus.LOADING:
  case DeliveryStatus.IN_DELIVERY:
    if (deliveryNote.material.type === MaterialType.NORMAL) {
      return 'UNLOAD_MATERIAL'
    } else {
      return 'UNLOAD_WASTE'
    }
  case DeliveryStatus.DELIVERED:
  default:
    return undefined
  }
}

export function computeSiteManagerConfirmFlow(deliveryNote: DeliveryNoteBean): SiteManagerConfirmFlow | undefined {
  if (deliveryNote.delivery_type === DeliveryType.CUSTOMER) {
    return computeSiteManagerConfirmFlowForCustomer(deliveryNote)
  } else {
    return computeSiteManagerConfirmFlowForHauler(deliveryNote)
  }
}

export function computeSiteManagerConfirmType(deliveryNote: DeliveryNoteBean): SiteManagerConfirmType | undefined {
  const flow = computeSiteManagerConfirmFlow(deliveryNote)
  if (!flow) {
    return undefined
  }
  if (flow === 'UNLOAD_MATERIAL') {
    switch (deliveryNote.status) {
    case DeliveryStatus.PENDING:
      return 'BY_PICTURE'
    case DeliveryStatus.LOADING:
    case DeliveryStatus.IN_DELIVERY:
      return 'BY_VALIDATION'
    default:
      return undefined
    }
  } else {
    return 'BY_PICTURE'
  }
}

// noinspection JSMethodCanBeStatic
export class SiteManagerDeliveryNoteConfirmModule implements Module<SiteManagerDeliveryNoteConfirmState, AppState> {
  namespaced = true
  state = {
    needReloading: true,
    deliveryNote: null,
    info: null,
    externalInfo: null
  }
  actions = {
    reloadState: this.reloadStateAction.bind(this),
    selectDeliveryNoteForConfirm: this.selectDeliveryNoteForConfirmAction.bind(this),
    registerForPushNotification: this.registerForPushNotificationAction.bind(this),
    registerForStoreChanges: this.registerForStoreChangesAction.bind(this),
    setWeighingSlipPicture: this.setWeighingSlipPictureAction.bind(this),
    setLocation: this.setLocationAction.bind(this),
    setTruckRegistration: this.setTruckRegistrationAction.bind(this),
    setNetWeight: this.setNetWeightAction.bind(this),
    setDay: this.setDayAction.bind(this),
    setComment: this.setCommentAction.bind(this),
    confirmDeparture: this.confirmDepartureAction.bind(this),
    confirmDelivery: this.confirmDeliveryAction.bind(this),
    updateDeliveryNoteFromUpcoming: this.updateDeliveryNoteFromUpcomingAction.bind(this),
    clearConfirmModule: this.clearConfirmModuleAction.bind(this)
  }
  mutations = {
    reloadState: this.reloadState.bind(this),
    setDeliveryNote: this.setDeliveryNote.bind(this),
    resetOrUpdateDeliveryNote: this.resetOrUpdateDeliveryNote.bind(this),
    setExternalConfirmInfo: (state, externalInfo) => state.externalInfo = externalInfo,
    setWeighingSlipPictureBean: this.setWeighingSlipPictureBean.bind(this),
    setLocation: (state, location) => this.updateAndSaveConfirmInfo(state, it => it.location = location),
    setNetWeight: (state, netWeight) => this.updateAndSaveConfirmInfo(state, it => it.netWeight = netWeight),
    setTruckRegistration: (state, truckRegistration) => this.updateAndSaveConfirmInfo(state, it => it.truckRegistration = truckRegistration),
    setDay: (state, day) => this.updateAndSaveConfirmInfo(state, it => it.day = day),
    setComment: (state, comment) => this.updateAndSaveConfirmInfo(state, it => it.comment = comment),
    setStoreSubscription: this.setStoreSubscription.bind(this),
    setPushNotificationSubscription: (state, subscription) => state.pushNotificationSubscription = subscription,
    resetState: this.resetState.bind(this)
  }
  getters = {
    confirmInfo: (state: SiteManagerDeliveryNoteConfirmState) => state.info,
    confirmDay: this.getConfirmDay.bind(this),
    externalConfirmInfo: (state: SiteManagerDeliveryNoteConfirmState) => state.externalInfo,
    deliveryNote: (state: SiteManagerDeliveryNoteConfirmState) => state.deliveryNote,
    getNextRoute: this.getNextRoute.bind(this),
    getPreviousRoute: this.getPreviousRoute.bind(this)
  }
  private readonly confirmInfoStore: LocalStore<SiteManagerConfirmInfoBean, string>
  
  constructor(
    private readonly i18n: I18n,
    private readonly ionicProxyService: IonicProxyService,
    private readonly deliveryNoteSyncService: DeliveryNoteSyncService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly deliveryNoteStore: LocalStore<DeliveryNoteBean, string>,
    private readonly siteManagerDeliveryNoteApi: SiteManagerDeliveryNoteApi,
    private readonly siteManagerDeliveryNotePictureApi: SiteManagerDeliveryNotePictureApi
  ) {
    this.confirmInfoStore = new LocalStore<SiteManagerConfirmInfoBean, string>({
      localStorageKey: STORAGE_KEY
    })
  }
  
  async reloadStateAction(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>
  ) {
    const confirmInfo = this.confirmInfoStore.getItem(STORE_KEY) || null
    
    let deliveryNote: DeliveryNoteBean | null = null
    if (confirmInfo) {
      const dn = this.deliveryNoteStore.getItem(confirmInfo.deliveryNoteId) || null
      if (dn) {
        deliveryNote = await this.deliveryNoteSyncService.syncWithPending('SITE_MANAGER', dn)
      }
    }
    
    // Since we lost the delivery note from the cache, we also remove the in progress load/unload.
    if (!confirmInfo || !deliveryNote) {
      if (confirmInfo) {
        await this.deliveryNoteSyncService.clearByIdAndType(confirmInfo.deliveryNoteId, confirmInfo.flow)
      }
      this.confirmInfoStore.deleteItem(STORE_KEY)
      return
    }
    
    context.commit('reloadState', {
      info: confirmInfo,
      deliveryNote: deliveryNote
    })
  }
  
  async selectDeliveryNoteForConfirmAction(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>,
    deliveryNoteId: string
  ) {
    await context.dispatch('registerForPushNotification')
    
    if (context.state.needReloading) {
      await context.dispatch('reloadState')
    }
    
    const operation = getItemOperation(this.deliveryNoteStore, (id: string) => this.siteManagerDeliveryNoteApi.fetchDeliveryNote(id))
    const deliveryNote = await operation.get({
      params: deliveryNoteId,
      allowOnlyStore: true,
      expirationInMs: DELIVERY_NOTE_VALIDITY
    })
    if (deliveryNote === null) {
      throw makeException(this.i18n, 'confirm.deleted')
    }
    const syncDeliveryNote = await this.deliveryNoteSyncService.syncWithPending(ROLE_SITE_MANAGER, deliveryNote)
    context.commit('setDeliveryNote', syncDeliveryNote)
    
    await context.dispatch('registerForStoreChanges')
  }
  
  async setWeighingSlipPictureAction(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>,
    params: SetPictureParams | null
  ) {
    const confirmInfo = context.state.info
    const deliveryNote = context.state.deliveryNote
    if (!confirmInfo || !deliveryNote) {
      return
    }
    if (params !== null) {
      const picture: PictureBean = {
        picture: params.picture,
        location: params.location,
        date: moment().format(),
        uploaded: false
      }
      const promise = this.uploadWeighingSlipPicture(confirmInfo.flow, deliveryNote, picture)
      context.commit('setWeighingSlipPictureBean', {picture, promise})
    } else {
      context.commit('setWeighingSlipPictureBean', null)
    }
  }
  
  async setLocationAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>, location?: LocationBean) {
    context.commit('setLocation', location)
  }
  
  async setNetWeightAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>, netWeight?: number | null) {
    context.commit('setNetWeight', netWeight)
  }
  
  async setTruckRegistrationAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>, truckRegistration?: number | null) {
    context.commit('setTruckRegistration', truckRegistration)
  }
  
  async setDayAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>, day?: Moment) {
    if (day === undefined) {
      context.commit('setDay', undefined)
      return
    }
    if (day.startOf('day').isSame(todayLocal())) {
      context.commit('setDay', null)
      return
    }
    const formattedDay = day.format(moment.HTML5_FMT.DATE)
    context.commit('setDay', formattedDay)
  }
  
  async setCommentAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>, comment?: string) {
    context.commit('setComment', comment)
  }
  
  async confirmDepartureAction(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>
  ): Promise<DeliveryNoteBean> {
    const confirmInfo = context.state.info
    const deliveryNote = context.state.deliveryNote
    if (confirmInfo == null || deliveryNote == null) {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    
    await this.waitForPictureUploads(context.state, confirmInfo)
    const result = await this.siteManagerDeliveryNoteApi.confirmLoad(
      confirmInfo.flow, confirmInfo.deliveryNoteId,
      {
        location: confirmInfo.location,
        day: confirmInfo.day,
        date: moment().format(),
        truck_registration: confirmInfo.truckRegistration,
        comment: confirmInfo.comment
      }
    )
  
    console.info('Cleaning site manager confirm flow after confirm action.')
    context.commit('resetState', false)
    this.confirmInfoStore.deleteItem(STORE_KEY)
    
    if (result.pending) {
      await context.dispatch(
        'message/setMessage',
        {message: translateMessage(this.i18n, 'confirm.pending'), timeoutInMs: 5000},
        {root: true}
      )
    }
    if (result.content !== undefined) {
      this.deliveryNoteStore.saveItem(result.content.id, result.content)
      return result.content
    } else {
      return deliveryNote
    }
  }
  
  async confirmDeliveryAction(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>
  ): Promise<DeliveryNoteBean> {
    const confirmInfo = context.state.info
    const deliveryNote = context.state.deliveryNote
    if (confirmInfo == null || deliveryNote == null) {
      throw makeException(this.i18n, 'confirm.unknown')
    }
    
    await this.waitForPictureUploads(context.state, confirmInfo)
    let result: SynchronizeResult<DeliveryNoteBean>
    if (confirmInfo.type === 'BY_VALIDATION') {
      result = await this.siteManagerDeliveryNoteApi.confirmUnload(
        confirmInfo.flow, confirmInfo.deliveryNoteId,
        {
          location: confirmInfo.location,
          day: confirmInfo.day,
          date: moment().format(),
        }
      )
    } else {
      result = await this.siteManagerDeliveryNoteApi.confirmUnloadWithPicture(
        confirmInfo.flow, confirmInfo.deliveryNoteId,
        {
          location: confirmInfo.location,
          day: confirmInfo.day,
          date: moment().format(),
          net_weight: confirmInfo.netWeight,
          truck_registration: confirmInfo.truckRegistration,
          comment: confirmInfo.comment
        }
      )
    }
  
    console.info('Cleaning site manager confirm flow after confirm action.')
    context.commit('resetState', false)
    this.confirmInfoStore.deleteItem(STORE_KEY)
    
    if (result.pending) {
      await context.dispatch(
        'message/setMessage',
        {message: translateMessage(this.i18n, 'confirm.pending'), timeoutInMs: 5000},
        {root: true}
      )
    }
    if (result.content !== undefined) {
      this.deliveryNoteStore.saveItem(result.content.id, result.content)
      return result.content
    } else {
      return deliveryNote
    }
  }
  
  private async registerForStoreChangesAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ) {
    context.commit('setStoreSubscription', undefined)
    
    const deliveryNoteId = context.state.info?.deliveryNoteId
    if (!deliveryNoteId) {
      return
    }
    
    const subscription = this.deliveryNoteStore.registerForChanges(deliveryNoteId, this.onDeliveryNoteChanges.bind(this, context))
    context.commit('setStoreSubscription', subscription)
  }
  
  // noinspection JSUnusedLocalSymbols
  private onDeliveryNoteChanges(
    context: ActionContext<DeliveryNoteConfirmState, AppState>,
    deliveryNoteId: string,
    deliveryNote: DeliveryNoteBean | null
  ) {
    if (deliveryNoteId !== context.state.info?.deliveryNoteId) {
      return
    }
    console.log('Updating delivery note in site manager confirm flow.')
    if (deliveryNote !== null) {
      context.commit('resetOrUpdateDeliveryNote', deliveryNote)
    } else {
      context.commit('setDeliveryNote', null)
    }
  }
  
  private async registerForPushNotificationAction(
    context: ActionContext<DeliveryNoteConfirmState, AppState>
  ) {
    if (context.state.pushNotificationSubscription) {
      return
    }
    const callback = this.onDeliveryNoteStatusChanged.bind(this, context)
    const subscription = this.pushNotificationService.registerForEventReceived({
      event: PushNotificationEvent.DELIVERY_NOTE_STATUS_CHANGED,
      schema: deliveryNoteStatusChangedSchema,
      callback
    })
    context.commit('setPushNotificationSubscription', subscription)
  }
  
  // noinspection JSUnusedLocalSymbols
  private async onDeliveryNoteStatusChanged(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>,
    event: string,
    data: DeliveryNoteStatusChangedBean
  ) {
    const info = context.state.info
    if (!info) {
      return
    }
    if (info.deliveryNoteId !== data.delivery_note_id) {
      return
    }
    
    const externalConfirmInfo = this.getExternalConfirmInfo(info, data)
    if (!externalConfirmInfo) {
      return
    }
    console.info('Push notification triggered confirmation of delivery note.')
    context.commit('setExternalConfirmInfo', externalConfirmInfo)
  }
  
  private getExternalConfirmInfo(info: SiteManagerConfirmInfoBean, data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    switch (info.flow) {
    case 'LOAD_WASTE':
      return this.getExternalConfirmInfoForLoadWaste(data)
    case 'UNLOAD_MATERIAL':
    case 'UNLOAD_MATERIAL_CUSTOMER':
      return this.getExternalConfirmInfoForUnloadMaterial(data)
    case 'UNLOAD_WASTE':
    case 'UNLOAD_WASTE_CUSTOMER':
      return this.getExternalConfirmInfoForUnloadWaste(data)
    default:
      return null
    }
  }
  
  private getExternalConfirmInfoForLoadWaste(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.IN_DELIVERY) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.siteManager.waste.load')
    }
  }
  
  private getExternalConfirmInfoForUnloadMaterial(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.DELIVERED) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.siteManager.material.unload')
    }
  }
  
  private getExternalConfirmInfoForUnloadWaste(data: DeliveryNoteStatusChangedBean): ExternalConfirmInfoBean | null {
    if (data.status !== DeliveryStatus.DELIVERED) {
      return null
    }
    return {
      message: translateMessage(this.i18n, 'externalConfirmation.siteManager.waste.unload')
    }
  }
  
  /**
   * We only wait for the first upload of images to finish either successfully or not.
   *
   * Handling of error is done by the sync module, it will put our confirm request in pending
   * in case the picture failed to upload due to missing internet.
   */
  private async waitForPictureUploads(
    state: SiteManagerDeliveryNoteConfirmState,
    confirmInfo: SiteManagerConfirmInfoBean
  ): Promise<void> {
    const deliveryNote = state.deliveryNote
    if (!deliveryNote || confirmInfo.flow === 'LOAD_WASTE') {
      return
    }
  
    const uploads: Promise<SynchronizeResult<void>>[] = []
    if (state.weighingSlipPictureUpload) {
      uploads.push(state.weighingSlipPictureUpload.wait())
    }
    await Promise.all(uploads)
  }
  
  private uploadWeighingSlipPicture(
    confirmFlow: SiteManagerConfirmFlow,
    deliveryNote: DeliveryNoteBean,
    picture?: PictureBean | null
  ): BroadcastPromise<SynchronizeResult<void>> | undefined {
    if (!picture) {
      return undefined
    }
    const promise = this.siteManagerDeliveryNotePictureApi.uploadWeighingSlipPicture(
      confirmFlow,
      deliveryNote.id,
      picture
    )
    const broadcastPromise = new BroadcastPromise(promise)
    broadcastPromise.wait().then(
      this.updatePictureBeanToUpdated.bind(this, picture),
      () => makeException(this.i18n, 'confirm.siteManager.pictureUploadFailed')
    )
    return broadcastPromise
  }
  
  private updatePictureBeanToUpdated(picture: PictureBean): void {
    picture.uploaded = true
    this.confirmInfoStore.saveItem(STORE_KEY, this.state.info)
  }
  
  private updateAndSaveConfirmInfo(state: SiteManagerDeliveryNoteConfirmState, callback: (confirmInfo: SiteManagerConfirmInfoBean) => void) {
    const confirmInfo = state.info
    if (confirmInfo) {
      callback(confirmInfo)
      this.saveConfirmInfo(confirmInfo)
    }
  }
  
  private saveConfirmInfo(info: SiteManagerConfirmInfoBean) {
    const infoToSave = {...info}
    
    // Remove images that are blob since they cannot be serialized
    if (infoToSave.weighingSlipPicture?.picture instanceof Blob) {
      infoToSave.weighingSlipPicture = undefined
    }
    
    this.confirmInfoStore.saveItem(STORE_KEY, infoToSave)
  }
  
  private updateDeliveryNoteFromUpcomingAction(
    context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>,
    updatedDeliveryNotes: Array<DeliveryNoteBean>
  ) {
    const confirmInfo = context.state.info
    if (!confirmInfo) {
      return
    }
    if (this.isUserInFlow()) {
      console.info('Do not update delivery note from upcoming since user is in flow.')
      return
    }
    const updatedDeliveryNote = _.find(updatedDeliveryNotes, it => it.id == confirmInfo.deliveryNoteId)
    // Clear the cache since it has been completed by another user.
    if (!updatedDeliveryNote) {
      context.commit('setDeliveryNote', null)
    }
  }
  
  private isUserInFlow(): boolean {
    const routeName = this.ionicProxyService.router?.currentRoute?.value?.name?.toString()
    if (!routeName) {
      return false
    }
    return _.valuesIn(SiteManagerDeliveryNoteConfirmRoute).indexOf(routeName) !== -1
  }
  
  private clearConfirmModuleAction(context: ActionContext<SiteManagerDeliveryNoteConfirmState, AppState>) {
    context.commit('setDeliveryNote', null)
  }
  
  private reloadState(
    state: SiteManagerDeliveryNoteConfirmState,
    newState: {info: SiteManagerConfirmInfoBean; deliveryNote: DeliveryNoteBean}
  ) {
    state.needReloading = false
    state.info = newState.info
    state.deliveryNote = newState.deliveryNote
  }
  
  private setDeliveryNote(state: SiteManagerDeliveryNoteConfirmState, deliveryNote: DeliveryNoteBean | null) {
    if (!deliveryNote) {
      console.info('Cleaning site manager confirm flow.')
      this.resetState(state)
      this.confirmInfoStore.deleteItem(STORE_KEY)
      return
    }
    
    const flow = computeSiteManagerConfirmFlow(deliveryNote)
    const type = computeSiteManagerConfirmType(deliveryNote)
    if (!type || !flow) {
      throw makeException(this.i18n, 'confirm.alreadyDelivered')
    }
    
    if (deliveryNote.id === state.info?.deliveryNoteId && flow === state.info?.flow && type === state.info?.type) {
      console.info('Updating delivery note since flow has not changed.')
      state.deliveryNote = deliveryNote
      return
    }
    
    console.info(`Setting flow (${flow}, ${type}) for delivery note.`)
    
    this.resetState(state)
    
    state.deliveryNote = deliveryNote
    const confirmInfo = {
      deliveryNoteId: deliveryNote.id,
      flow: flow,
      type: type
    }
    state.info = confirmInfo
    this.confirmInfoStore.saveItem(STORE_KEY, confirmInfo)
  }
  
  private resetOrUpdateDeliveryNote(state: SiteManagerDeliveryNoteConfirmState, deliveryNote: DeliveryNoteBean) {
    if (deliveryNote.id !== state.info?.deliveryNoteId) {
      return
    }
    
    const currentFlow = state.info?.flow
    const currentType = state.info?.type
    
    if (this.isUserInFlow()) {
      console.info(`Only updating delivery note in flow (${currentFlow}, ${currentType}) since the user is in flow.`)
      state.deliveryNote = deliveryNote
      return
    }
    
    const newFlow = computeSiteManagerConfirmFlow(deliveryNote)
    const newType = computeSiteManagerConfirmType(deliveryNote)
    
    if (newFlow !== currentFlow || newType !== currentType) {
      console.info(`Resetting delivery note in flow (${currentFlow}, ${currentType}) since new flow is required: ${newFlow}, ${newType}`)
      this.resetState(state)
      this.confirmInfoStore.deleteItem(STORE_KEY)
    } else {
      console.info(`Updating delivery note in flow (${currentFlow}, ${currentType}).`)
      state.deliveryNote = deliveryNote
    }
  }
  
  private resetState(
    state: SiteManagerDeliveryNoteConfirmState,
    resetSync?: boolean
  ) {
    if (resetSync === true) {
      if (state.info) {
        this.deliveryNoteSyncService.clearByIdAndType(state.info.deliveryNoteId, state.info.flow).then()
      }
    }
    state.deliveryNote = null
    state.info = null
    state.externalInfo = null
    state.weighingSlipPictureUpload = undefined
    this.setStoreSubscription(state, undefined)
  }
  
  private setWeighingSlipPictureBean(state: SiteManagerDeliveryNoteConfirmState, params: SetPictureBeanParams | null) {
    if (params !== null) {
      this.updateAndSaveConfirmInfo(state, info => info.weighingSlipPicture = params.picture)
      state.weighingSlipPictureUpload = params.promise
    } else {
      this.updateAndSaveConfirmInfo(state, info => info.weighingSlipPicture = null)
      state.weighingSlipPictureUpload = undefined
    }
  }
  
  private setStoreSubscription(state: SiteManagerDeliveryNoteConfirmState, subscription?: Subscription) {
    if (state.storeSubscription !== subscription) {
      state.storeSubscription?.unregister()
      state.storeSubscription = subscription
    }
  }
  
  private getConfirmDay(state: SiteManagerDeliveryNoteConfirmState): Moment | undefined {
    if (state?.info?.day == null) {
      return undefined
    }
    return parseDateLocal(state.info.day).clone()
  }
  
  private getNextRoute(state: SiteManagerDeliveryNoteConfirmState): () => string | undefined {
    return () => {
      const deliveryNote = state.deliveryNote
      const info = state.info
      if (!info || !deliveryNote) {
        return SiteManagerDeliveryNoteDetailsRoute
      }
      if (info.flow === 'LOAD_WASTE') {
        return this.getNextRouteForLoadWasteByPicture(deliveryNote, info)
      } else if (info.flow === 'UNLOAD_MATERIAL') {
        if (info.type === 'BY_VALIDATION') {
          return this.getNextRouteForUnloadMaterialByValidation(deliveryNote, info)
        } else {
          return this.getNextRouteForUnloadByPicture(deliveryNote, info)
        }
      } else if (info.flow === 'UNLOAD_WASTE') {
        return this.getNextRouteForUnloadByPicture(deliveryNote, info)
      } else if (info.flow === 'UNLOAD_WASTE_CUSTOMER') {
        return this.getNextRouteForUnloadWasteByCustomer(deliveryNote, info)
      } else if (info.flow === 'UNLOAD_MATERIAL_CUSTOMER') {
        return this.getNextRouteForUnloadMaterialByCustomer(deliveryNote, info)
      } else {
        return SiteManagerDeliveryNoteDetailsRoute
      }
    }
  }
  
  private getNextRouteForLoadWasteByPicture(deliveryNote: DeliveryNoteBean, info: SiteManagerConfirmInfoBean): string {
    const mustValidateDate = this.mustValidateDateForValidation(deliveryNote)
    if (mustValidateDate && info.day === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.DAY
    }
    if (!deliveryNote.truck_registration && info.truckRegistration === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION
    }
    return SiteManagerDeliveryNoteConfirmRoute.CONFIRM_WITH_COMMENT
  }
  
  private getNextRouteForUnloadMaterialByValidation(deliveryNote: DeliveryNoteBean, info: SiteManagerConfirmInfoBean): string {
    const mustTakeWeighingSlip = this.mustTakeWeighingSlipForValidation(deliveryNote)
    const mustValidateDate = this.mustValidateDateForValidation(deliveryNote)
    if (mustTakeWeighingSlip && info.weighingSlipPicture === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP
    }
    if (mustValidateDate && info.day === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.DAY
    }
    return SiteManagerDeliveryNoteConfirmRoute.CONFIRM
  }
  
  private getNextRouteForUnloadByPicture(deliveryNote: DeliveryNoteBean, info: SiteManagerConfirmInfoBean): string {
    const mustValidateDate = this.mustValidateDateForValidation(deliveryNote)
    if (!info.weighingSlipPicture) {
      return SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP
    }
    if (!deliveryNote.net_weight && info.netWeight === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.WEIGHT
    }
    if (mustValidateDate && info.day === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.DAY
    }
    if (!deliveryNote.truck_registration && info.truckRegistration === undefined) {
      return SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION
    }
    return SiteManagerDeliveryNoteConfirmRoute.CONFIRM_WITH_COMMENT
  }
  
  private getNextRouteForUnloadWasteByCustomer(deliveryNote: DeliveryNoteBean, info: SiteManagerConfirmInfoBean): string {
    // Same flow as unload with picture.
    return this.getNextRouteForUnloadByPicture(deliveryNote, info)
  }
  
  private getNextRouteForUnloadMaterialByCustomer(deliveryNote: DeliveryNoteBean, info: SiteManagerConfirmInfoBean): string {
    // Same flow as unload with picture.
    return this.getNextRouteForUnloadByPicture(deliveryNote, info)
  }
  
  private getPreviousRoute(state: SiteManagerDeliveryNoteConfirmState): (currentRoute: string) => string | undefined {
    return (currentRoute) => {
      const info = state.info
      const deliveryNote = state.deliveryNote
      if (!info || !deliveryNote) {
        return SiteManagerDeliveryNoteDetailsRoute
      }
      if (info.flow === 'LOAD_WASTE') {
        return this.getPreviousRouteForLoadWasteByPicture(currentRoute, deliveryNote)
      } else if (info.flow === 'UNLOAD_MATERIAL') {
        if (info.type === 'BY_VALIDATION') {
          return this.getPreviousRouteForUnloadMaterialByValidation(currentRoute, deliveryNote)
        } else {
          return this.getPreviousRouteForUnloadByPicture(currentRoute, deliveryNote)
        }
      } else if (info.flow === 'UNLOAD_WASTE') {
        return this.getPreviousRouteForUnloadByPicture(currentRoute, deliveryNote)
      } else if (info.flow === 'UNLOAD_WASTE_CUSTOMER') {
        return this.getPreviousRouteForUnloadWasteByCustomer(currentRoute, deliveryNote)
      } else if (info.flow === 'UNLOAD_MATERIAL_CUSTOMER') {
        return this.getPreviousRouteForUnloadMaterialByCustomer(currentRoute, deliveryNote)
      } else {
        return SiteManagerDeliveryNoteDetailsRoute
      }
    }
  }
  
  private getPreviousRouteForLoadWasteByPicture(currentRoute: string, deliveryNote: DeliveryNoteBean) {
    const mustValidateDate = this.mustValidateDateForValidation(deliveryNote)
    switch (currentRoute) {
    case SiteManagerDeliveryNoteConfirmRoute.CONFIRM_WITH_COMMENT:
      if (!deliveryNote.truck_registration) {
        return SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION:
      if (mustValidateDate) {
        return SiteManagerDeliveryNoteConfirmRoute.DAY
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.DAY:
    default:
      return SiteManagerDeliveryNoteDetailsRoute
    }
  }
  
  private getPreviousRouteForUnloadMaterialByValidation(currentRoute: string, deliveryNote: DeliveryNoteBean) {
    const mustTakeWeighingSlip = this.mustTakeWeighingSlipForValidation(deliveryNote)
    const mustValidateDate = this.mustValidateDateForValidation(deliveryNote)
    switch (currentRoute) {
    case SiteManagerDeliveryNoteConfirmRoute.CONFIRM:
      if (mustValidateDate) {
        return SiteManagerDeliveryNoteConfirmRoute.DAY
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.DAY:
      if (mustTakeWeighingSlip) {
        return SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP:
      // eslint-disable-next-line no-fallthrough
    default:
      return SiteManagerDeliveryNoteDetailsRoute
    }
  }
  
  private getPreviousRouteForUnloadByPicture(currentRoute: string, deliveryNote: DeliveryNoteBean) {
    const mustValidateDate = this.mustValidateDateForValidation(deliveryNote)
    switch (currentRoute) {
    case SiteManagerDeliveryNoteConfirmRoute.CONFIRM_WITH_COMMENT:
      if (!deliveryNote.truck_registration) {
        return SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.TRUCK_REGISTRATION:
      if (mustValidateDate) {
        return SiteManagerDeliveryNoteConfirmRoute.DAY
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.DAY:
      if (!deliveryNote.net_weight) {
        return SiteManagerDeliveryNoteConfirmRoute.WEIGHT
      }
      // eslint-disable-next-line no-fallthrough
    case SiteManagerDeliveryNoteConfirmRoute.WEIGHT:
      return SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP
    case SiteManagerDeliveryNoteConfirmRoute.WEIGHING_SLIP:
    default:
      return SiteManagerDeliveryNoteDetailsRoute
    }
  }
  
  private getPreviousRouteForUnloadWasteByCustomer(currentRoute: string, deliveryNote: DeliveryNoteBean) {
    return this.getPreviousRouteForUnloadByPicture(currentRoute, deliveryNote)
  }
  
  private getPreviousRouteForUnloadMaterialByCustomer(currentRoute: string, deliveryNote: DeliveryNoteBean) {
    return this.getPreviousRouteForUnloadByPicture(currentRoute, deliveryNote)
  }
  
  private mustTakeWeighingSlipForValidation(deliveryNote: DeliveryNoteBean): boolean {
    const weighingSlip = deliveryNote?.pictures?.weighing_slip
    if (weighingSlip == null) {
      return false
    }
    return !weighingSlip
  }
  
  private mustValidateDateForValidation(deliveryNote: DeliveryNoteBean): boolean {
    const today = todayLocal()
    const mostAccurateDay = getMostAccurateDay(deliveryNote)
    return today.isAfter(mostAccurateDay, 'day')
  }
}
